<template>
  <div class="tabs" id="tabs">
    <div class="tabs-group fx-row">
      <template v-for="(item, index) in dtabs">
        <div
          class="tab ft-sz-13 ft-bold"
          :class="{ active: activeIndex === item.id }"
          :key="index"
          @click="changeTab(item.id)"
        >
          {{ item.name }}({{ item.price }}元)
          <!-- <span class="c-red ft-sz-12" v-if="item.id == 'debt' || item.id == 'overdue'">NEW</span> -->
          <!-- <span class="c-whites ft-sz-12" v-if="item.price > 0"> -->

          <!-- </span> -->
          <!-- <span
            class="tab-refresh"
            v-if="item.price > 0 && activeIndex === item.id"
            @click.stop="$emit('refresh-tab', item.id)"
            ><i class="el-icon-refresh-left"></i
          ></span> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/**
 *  若需要改动tabs,需要修改以下几处代码逻辑
 *  1. view-tabs.vue => tabs数组
 *  2. view-bar.vue => 切换tab的组件以及其他相应的activeIndex顺序
 */
import { mapMutations } from "vuex";
import tabs from '../tabs';
import {setPrice} from '../tabs';
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 0
    },
    queryType: {
      type: Number,
      default: -1
    },
  },
  computed:{
    dtabs:function(){
      var tabs1 = tabs;
      if(this.queryType == 1){
        tabs1 = tabs1.filter(tab => tab.id == 'NOTE' || tab.id == 'MORE_NOTE' || tab.id == '')
        console.log(tabs1)
      }
      return tabs1;
    }
  },
  mounted() {
    // var tabs1 = tabs;
    // this.tabs = tabs1
    // console.log('tabs1',this.queryType)
    // if(this.queryType == 1){
    //   tabs1 = tabs1.filter(tab => tab.id == 'NOTE' || tab.id == 'MORE_NOTE')
    //   console.log(tabs1)
    //   this.tabs = tabs1;
    // }else{
    //   this.tabs = tabs1
    // }
  },
  created() {

    Promise.all(
      this.tabs.filter(tab => !tab.pro || this.pro).map((tab) => {
        let api = tab.api;
        if (!api) {
          return new Promise((res, _) => res(tab));
        }
        if (typeof api == "function") {
          api = [api];
        }
        return Promise.all(
          api.map(_ =>
            _({ uid: -1, price: "true" },tab.id).then(res => {
              console.log('then',res.amount / 100)
              return { price: res.amount / 100 };
            })
          )
        )
          .then(res =>
            res.reduce((a, b) => {
              console.log('reduce',a,b)
              ({ ...a, price: a.price + b.price })
            })
          )
          .then(res => ({
            ...tab,
            ...res
          }))
          .then(r => {
             console.log('r.id',r.id,r.price)
            setPrice(r.id, r.price);
            return r;
          })
      })
    );

  },
  data() {
    return {
      tabs: tabs,
    };

  },

  methods: {
    ...mapMutations("userReport", ["setIndex", "setPrice"]),
    changeTab(index) {
      let price = this.tabs.filter((item, i) => item.id === index)[0].price;
      console.log('price',price)
      this.setPrice(price);
      this.$emit("change-tab", index);
    },
    setAueryType(queryType){
      var tabs1 = tabs;
      console.log('tabs1',this.queryType)
      if(this.queryType == 1){
        tabs1 = tabs1.filter(tab => tab.id == 'NOTE' || tab.id == 'MORE_NOTE')
        console.log(tabs1)

        this.tabs = tabs1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  // margin-top: 15px;
  // padding: 36px 0 0 40px;
  // background-color: #fff;
  position: relative;
    // right: 5px;
    // top: 2px;
    margin-top: 25px;
    overflow: auto;
    z-index: 999;
    background-color: #F3F8FE;
  .tab {
    // min-width: 125px;
    padding: 14px 12px;
    height: 45px;
    margin-right: 10px;
    text-align: center;
    background-color: #dbe6f3;
    border-radius: 2px 2px 0 0;
    box-sizing: border-box;
    // margin: 2px 5px;
    color: #7a8b9a;
    cursor: pointer;
    white-space: nowrap;
    width: max-content;
    &.active {
      background-color: #1890ff;
      color: #fff;
      border-top: 1px solid #1890ff;
      border-left: 1px solid #1890ff;
      border-right: 1px solid #1890ff;
      border-radius: 5px 5px 0px 0px;
    }
    .tab-refresh {
      margin-left: 0.2em;
    }
  }
}
@media screen and (max-width: 480px) {

.tabs-group{
  overflow-x: auto;

}
.tabs{
  padding: 1rem 0.5rem 0;

 .tab{
    min-width:9rem;
  }
}
}
</style>
